/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCompanyUsers$Json } from '../fn/user/get-company-users-json';
import { GetCompanyUsers$Json$Params } from '../fn/user/get-company-users-json';
import { getCompanyUsers$Plain } from '../fn/user/get-company-users-plain';
import { GetCompanyUsers$Plain$Params } from '../fn/user/get-company-users-plain';
import { getUser$Json } from '../fn/user/get-user-json';
import { GetUser$Json$Params } from '../fn/user/get-user-json';
import { getUser$Plain } from '../fn/user/get-user-plain';
import { GetUser$Plain$Params } from '../fn/user/get-user-plain';
import { getUsers$Json } from '../fn/user/get-users-json';
import { GetUsers$Json$Params } from '../fn/user/get-users-json';
import { getUsers$Plain } from '../fn/user/get-users-plain';
import { GetUsers$Plain$Params } from '../fn/user/get-users-plain';
import { moveUserToCompany$Json } from '../fn/user/move-user-to-company-json';
import { MoveUserToCompany$Json$Params } from '../fn/user/move-user-to-company-json';
import { moveUserToCompany$Plain } from '../fn/user/move-user-to-company-plain';
import { MoveUserToCompany$Plain$Params } from '../fn/user/move-user-to-company-plain';
import { updateUser$Json } from '../fn/user/update-user-json';
import { UpdateUser$Json$Params } from '../fn/user/update-user-json';
import { updateUser$Plain } from '../fn/user/update-user-plain';
import { UpdateUser$Plain$Params } from '../fn/user/update-user-plain';
import { updateUserModuleAccess$Json } from '../fn/user/update-user-module-access-json';
import { UpdateUserModuleAccess$Json$Params } from '../fn/user/update-user-module-access-json';
import { updateUserModuleAccess$Plain } from '../fn/user/update-user-module-access-plain';
import { UpdateUserModuleAccess$Plain$Params } from '../fn/user/update-user-module-access-plain';
import { UserCto } from '../models/user-cto';
import { UserUpdateContextCto } from '../models/user-update-context-cto';
import { UserUpdateResponseCto } from '../models/user-update-response-cto';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain$Response(params: GetUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return getUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain(params: GetUser$Plain$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.getUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json$Response(params: GetUser$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return getUser$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json(params: GetUser$Json$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.getUser$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Plain$Response(params: UpdateUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return updateUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Plain(params: UpdateUser$Plain$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.updateUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Json$Response(params: UpdateUser$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return updateUser$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Json(params: UpdateUser$Json$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.updateUser$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /** Path part for operation `getCompanyUsers()` */
  static readonly GetCompanyUsersPath = '/user/all/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers$Plain$Response(params: GetCompanyUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserUpdateContextCto>>> {
    return getCompanyUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers$Plain(params: GetCompanyUsers$Plain$Params, context?: HttpContext): Observable<Array<UserUpdateContextCto>> {
    return this.getCompanyUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserUpdateContextCto>>): Array<UserUpdateContextCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUsers$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers$Json$Response(params: GetCompanyUsers$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserUpdateContextCto>>> {
    return getCompanyUsers$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUsers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers$Json(params: GetCompanyUsers$Json$Params, context?: HttpContext): Observable<Array<UserUpdateContextCto>> {
    return this.getCompanyUsers$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserUpdateContextCto>>): Array<UserUpdateContextCto> => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/user/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain$Response(params?: GetUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserCto>>> {
    return getUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain(params?: GetUsers$Plain$Params, context?: HttpContext): Observable<Array<UserCto>> {
    return this.getUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserCto>>): Array<UserCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Json$Response(params?: GetUsers$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserCto>>> {
    return getUsers$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Json(params?: GetUsers$Json$Params, context?: HttpContext): Observable<Array<UserCto>> {
    return this.getUsers$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserCto>>): Array<UserCto> => r.body)
    );
  }

  /** Path part for operation `moveUserToCompany()` */
  static readonly MoveUserToCompanyPath = '/user/{userId}/moveToCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveUserToCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveUserToCompany$Plain$Response(params: MoveUserToCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return moveUserToCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveUserToCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveUserToCompany$Plain(params: MoveUserToCompany$Plain$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.moveUserToCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveUserToCompany$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveUserToCompany$Json$Response(params: MoveUserToCompany$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateContextCto>> {
    return moveUserToCompany$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveUserToCompany$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveUserToCompany$Json(params: MoveUserToCompany$Json$Params, context?: HttpContext): Observable<UserUpdateContextCto> {
    return this.moveUserToCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateContextCto>): UserUpdateContextCto => r.body)
    );
  }

  /** Path part for operation `updateUserModuleAccess()` */
  static readonly UpdateUserModuleAccessPath = '/user/{userId}/access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserModuleAccess$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserModuleAccess$Plain$Response(params: UpdateUserModuleAccess$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateResponseCto>> {
    return updateUserModuleAccess$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserModuleAccess$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserModuleAccess$Plain(params: UpdateUserModuleAccess$Plain$Params, context?: HttpContext): Observable<UserUpdateResponseCto> {
    return this.updateUserModuleAccess$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateResponseCto>): UserUpdateResponseCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserModuleAccess$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserModuleAccess$Json$Response(params: UpdateUserModuleAccess$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateResponseCto>> {
    return updateUserModuleAccess$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserModuleAccess$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserModuleAccess$Json(params: UpdateUserModuleAccess$Json$Params, context?: HttpContext): Observable<UserUpdateResponseCto> {
    return this.updateUserModuleAccess$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateResponseCto>): UserUpdateResponseCto => r.body)
    );
  }

}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * abacaccesslevelrename Pipe
 *
 * Turns enumed acces level values into something readable
 * 
 * Usage: ` item.accessLevel | abacaccesslevelrename`
 *
 * @param access string - string from enum
 * @return string
 */
@Pipe({
    name: 'abacaccesslevelrename'
})
export class ABACAccessLevelRenamePipe implements PipeTransform {
    transform(access: string): string {
        switch (access) {
            case "LimitedAccess": return "Limited";
            case "AllModuleAdmin":
            case "AllCompanyAdmin": return "Admin";
            case "FullAccess": return "Full";
            case "NoAccess": return "No Access"
            default: return access;
        }
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import {
    DetailedCompanyCto,
    ModuleAccessConfigurationCto,
    TimeTrialExtensionRequestCto,
    UserUpdateContextCto
} from 'src/app/api/models';
import { TimeTrialCto } from 'src/app/api/models/time-trial-cto';
import { CompanyService, UserService } from 'src/app/api/services';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
    PermissionsService,
    Permissions
} from 'src/app/shared/services/permissions.service';
import { CompanyExtendTimeTrialComponent } from '../company-extend-time-trial/company-extend-time-trial.component';
import { CompanyUpgradeDetailsCto } from '../../api/models/company-upgrade-details-cto';
import { UpgradeCompanyDialogComponent, UpgradeCompanyDialogData } from '../upgrade-company-dialog/upgrade-company-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { ABACAccessLevelRenamePipe } from '../../shared/pipes/abacaccesslevelrename.pipe';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
    editCompanyForm: FormGroup;

    public canEdit = false;

    public company?: DetailedCompanyCto;
    public smac: MatTableDataSource<ModuleAccessConfigurationCto> =
        new MatTableDataSource<ModuleAccessConfigurationCto>();
    public displayedColumns: string[] = [
        'name',
        'accessLevel',
        'experienceType',
        'accountType'
    ];

    public timeTrialsDataSource: MatTableDataSource<TimeTrialCto> =
        new MatTableDataSource<TimeTrialCto>();
    public timeTrials?: TimeTrialCto[];
    public timeTrialDisplayedColumns: string[] = [
        'timeTrialId',
        'product',
        'startDate',
        'endDate',
        'durationDays'
    ];

    public upgradeDetailsDataSource: MatTableDataSource<CompanyUpgradeDetailsCto> =
        new MatTableDataSource<CompanyUpgradeDetailsCto>();
    public upgradeDetails?: CompanyUpgradeDetailsCto[];
    public upgradeDetailsDisplayedColumns: string[] = [
        'status',
        'startDate'
    ];
    public canTriggerCompanyUpgrade = false;

    public usersDataSource: MatTableDataSource<UserUpdateContextCto> =
        new MatTableDataSource<UserUpdateContextCto>();
    public usersDetails?: UserUpdateContextCto[];
    public usersCount = 0;
    private usersPaginator: MatPaginator | undefined;
    public usersResultLoading = true;
    public usersDisplayedColumns: string[] = [
        'firstName',
        'lastName',
        'email',
        'role',
        'pi2OrgAdmin',
        'pi2Hire',
        'pi2Inspire',
        'pi2Design',
        'pi2Diagnose',
        "pi2Cog"
    ];

    public loading = false;

    private selectedId = '';

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.usersPaginator = mp;
        this.usersDataSource.paginator = this.usersPaginator;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly companyService: CompanyService,
        private readonly userService: UserService,
        permissionsService: PermissionsService,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar
    ) {
        this.canEdit = permissionsService.hasPermission(
            Permissions.UserOpsWrite
        );
        this.canTriggerCompanyUpgrade = permissionsService.hasPermission(
            Permissions.UpgradeWrite
        );

        this.editCompanyForm = this.fb.group({
            name: [{ value: null, disabled: true }, Validators.required],
            accountStatus: [
                { value: null, disabled: true },
                Validators.required
            ],
            anonymizationStatus: [
                {
                    value: null,
                    disabled: true
                },
                Validators.required
            ],
            companyId: [{ value: null, disabled: true }, Validators.required],
            companyGuid: [{ value: null, disabled: true }, Validators.required],
            isDeleted: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            isActiveIn15X: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            isPaused: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            isMFAEnabled: [
                { value: null, disabled: true },
                Validators.required
            ],
            isInGoodStanding: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            isOnHold: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            hasApiAccess: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            hasAtsAccess: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            hasHrisAccess: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            apiCompanyId: [{ value: null, disabled: true }]
        });
    }

    get companyId(): string | undefined {
        return this.editCompanyForm.controls['companyId'].value ?? undefined;
    }

    get companyGuid(): string | undefined {
        return this.editCompanyForm.controls['companyGuid'].value ?? undefined;
    }

    get apiCompanyId(): string | undefined {
        return this.editCompanyForm.controls['apiCompanyId'].value ?? undefined;
    }

    get isDeleted(): boolean | undefined {
        return this.editCompanyForm.controls['isDeleted'].value ?? undefined;
    }

    get isInGoodStanding(): boolean | undefined {
        return (
            this.editCompanyForm.controls['isInGoodStanding'].value ?? undefined
        );
    }

    get isActiveIn15X(): boolean | undefined {
        return (
            this.editCompanyForm.controls['isActiveIn15X'].value ?? undefined
        );
    }

    get isPaused(): boolean | undefined {
        return this.editCompanyForm.controls['isPaused'].value ?? undefined;
    }

    get isMFAEnabled(): boolean | undefined {
        return this.editCompanyForm.controls['isMFAEnabled'].value ?? undefined;
    }

    get isOnHold(): boolean | undefined {
        return this.editCompanyForm.controls['isOnHold'].value ?? undefined;
    }

    get anonymizationStatus(): number | undefined {
        return (
            this.editCompanyForm.controls['anonymizationStatus'].value ??
            undefined
        );
    }

    get hasApiAccess(): boolean | undefined {
        return this.editCompanyForm.controls['hasApiAccess'].value ?? undefined;
    }

    get hasAtsAccess(): boolean | undefined {
        return this.editCompanyForm.controls['hasAtsAccess'].value ?? undefined;
    }

    get hasHrisAccess(): boolean | undefined {
        return this.editCompanyForm.controls['hasHrisAccess'].value ?? undefined;
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.selectedId = params['id'];
            if (this.selectedId) {
                this.populateForm();
                this.populateUserForm();
            }
        });
    }

    public save(): void {
        if (this.editCompanyForm.valid) {
            this.companyService
                .updateCompany$Json({
                    companyId: this.selectedId,
                    body: {
                        companyId: this.selectedId,
                        isAccountPaused: this.isPaused,
                        isActiveIn15X: this.isActiveIn15X,
                        isDeleted: this.isDeleted,
                        isInGoodStanding: this.isInGoodStanding,
                        isMFAEnabled: this.isMFAEnabled,
                        isOnHold: this.isOnHold,
                        anonymizationStatusId: this.anonymizationStatus,
                        hasApiAccess: this.hasApiAccess,
                        hasAtsAccess: this.hasAtsAccess,
                        hasHrisAccess: this.hasHrisAccess
                    }
                })
                .subscribe({
                    next: (company) => {
                        this.resetForm(company);
                        this.notificationService.showSuccessNotification(
                            'Save Successful'
                        );
                    },
                    error: (errorMessage) => {
                        this.notificationService.showErrorNotification(
                            'Error Saving: ' + errorMessage
                        );
                    }
                });
        }
    }

    public back(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
        this.company = undefined;
        this.timeTrials = undefined;
    }

    public reset(): void {
        this.populateForm();
        this.populateUserForm();
    }

    public selectCompany(id: string): void {
        this.selectedId = id;
        this.router.navigate([this.selectedId], { relativeTo: this.route });
    }

    public extendTimeTrial(): void {
        const dialogRef = this.dialog.open(CompanyExtendTimeTrialComponent, {
            data: {
                companyId: this.companyGuid
            } as TimeTrialExtensionRequestCto
        });

        dialogRef.afterClosed().subscribe(() => this.reset());
    }

    public executeDataCorrection(): void {
        const dialogRef = this.dialog.open(UpgradeCompanyDialogComponent, {
            data: {
                companyId: this.companyGuid,
                companyName: this.company?.company?.name
            } as UpgradeCompanyDialogData
        });

        dialogRef.afterClosed().subscribe(() => this.reset());
    }

    public transformPermissionAccessText(access: string): string {
        switch (access) {
            case "LimitedAccess": return "Limited";
            case "AllModuleAdmin":
            case "AllCompanyAdmin": return "Admin";
            case "FullAccess": return "Full";
            default: return access;
        }
    }

    private populateForm(): void {
        this.loading = true;
        forkJoin({
            timetrialData: this.companyService.getCompanyTimeTrial$Json({
                companyId: this.selectedId
            }),
            companyData: this.companyService.getCompany$Json({
                companyId: this.selectedId
            }),
            upgradeData: this.companyService.getCompanyUpgrade$Json({
                companyId: this.selectedId
            })

        }).subscribe((result) => {
            this.timeTrials = result.timetrialData;
            this.upgradeDetails = result.upgradeData;
            this.resetForm(result.companyData);
        });
    }

    /* Isolated from populateForm to not block page loading. Some companies have 1000s of Users */
    private populateUserForm(): void {
        this.usersResultLoading = true;
        this.userService.getCompanyUsers$Json({
            companyId: this.selectedId
        }).subscribe((result) => {
            this.usersDetails = result;
            this.resetUserForm();
        });
    }

    private resetUserForm() {
        if (this.usersDetails != null) {
            this.usersDataSource = new MatTableDataSource(this.usersDetails);
            this.usersCount = this.usersDetails.length;
            if (this.usersPaginator)
                this.usersDataSource.paginator = this.usersPaginator;
        }
        this.usersResultLoading = false;
    }

    private resetForm(company: DetailedCompanyCto): void {
        this.company = company;

        this.editCompanyForm.reset({
            name: this.company.company?.name,
            accountStatus:
                this.company.company?.accountStatus?.accountStatusName,
            anonymizationStatus:
                this.company.company?.anonymizationStatus
                    ?.anonymizationStatusId,
            companyId: this.company.company?.companyId,
            companyGuid: this.company.company?.companyGuid,
            isDeleted: this.company.company?.isDeleted,
            isActiveIn15X: this.company.company?.isActiveIn15X,
            isPaused: this.company.company?.isAccountPaused,
            isMFAEnabled: this.company.company?.isMfaenabled,
            isInGoodStanding: this.company.company?.isInGoodStanding,
            isOnHold: this.company.company?.isOnHold,
            apiCompanyId: this.company.company?.apiCompanyId,
            hasApiAccess: this.company.company?.integrationSettings?.hasApiAccess,
            hasAtsAccess: this.company.company?.integrationSettings?.hasAtsAccess,
            hasHrisAccess: this.company.company?.integrationSettings?.hasHrisAccess
        });

        if (
            this.company.company?.anonymizationStatus?.anonymizationStatusId ==
            2 &&
            this.canEdit
        ) {
            this.editCompanyForm.controls['anonymizationStatus'].enable();
        } else {
            this.editCompanyForm.controls['anonymizationStatus'].disable();
        }

        if (
            this.company.softwareModuleAccessConfiguration
                ?.softwareModuleAccessConfig != null
        ) {
            this.smac = new MatTableDataSource(
                this.company.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
            );
        }

        if (this.timeTrials != null) {
            this.timeTrialsDataSource = new MatTableDataSource(this.timeTrials);
        }

        if (this.upgradeDetails != null) {
            this.upgradeDetailsDataSource = new MatTableDataSource(this.upgradeDetails);
        }

        this.loading = false;
    }
}

@if(company){
<button mat-stroked-button color="primary" (click)="back()" class="back-button">
    Back
</button>
}

@if(!company){
<app-company-search selectionType="guid" (companySelected)="selectCompany($event)"></app-company-search>
}

@if(loading){
<mat-spinner></mat-spinner>
}

@if(!loading && company){
<div>
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Company: {{ company.company?.name }}
            </mat-card-title>
        </mat-card-header>

        <mat-divider style="position: relative; margin: 25px 0 25px 0"></mat-divider>

        <mat-card-content>
            <form [formGroup]="editCompanyForm">
                <mat-grid-list cols="3" rowHeight="100px" style="max-width: 1500px">
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Account Status</mat-label>
                            <input matInput formControlName="accountStatus" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Anonymization Status</mat-label>
                            <mat-select formControlName="anonymizationStatus">
                                <mat-option [value]="1">Not Applicable</mat-option>
                                <mat-option [value]="2">Anonymization Requested</mat-option>
                                <mat-option [value]="3" [disabled]="true">Anonymization Completed</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Company ID</mat-label>
                            <input matInput formControlName="companyId" />
                        </mat-form-field>
                        <app-copy-to-clipboard [content]="companyId"></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Company GUID</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard [content]="companyGuid"></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>API Company ID</mat-label>
                            <input matInput formControlName="apiCompanyId" />
                        </mat-form-field>
                        <app-copy-to-clipboard [content]="apiCompanyId"></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-label class="company-radio-mat-label">Is Deleted</mat-label>
                        <mat-slide-toggle formControlName="isDeleted"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Is Active In 15X</mat-label>
                        <mat-slide-toggle formControlName="isActiveIn15X"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Is Account Paused</mat-label>
                        <mat-slide-toggle formControlName="isPaused"></mat-slide-toggle>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-label class="company-radio-mat-label">Is MFA Enabled</mat-label>
                        <mat-slide-toggle formControlName="isMFAEnabled"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Is On Hold</mat-label>
                        <mat-slide-toggle formControlName="isOnHold"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Is In Good Standing</mat-label>
                        <mat-slide-toggle formControlName="isInGoodStanding"></mat-slide-toggle>
                    </mat-grid-tile>
                    @if(company.company?.integrationSettings){
                    <mat-grid-tile>
                        <mat-label class="company-radio-mat-label">Has API Access</mat-label>
                        <mat-slide-toggle formControlName="hasApiAccess"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Has ATS Access</mat-label>
                        <mat-slide-toggle formControlName="hasAtsAccess"></mat-slide-toggle>

                        <mat-label class="company-radio-mat-label">Has HRIS Access</mat-label>
                        <mat-slide-toggle formControlName="hasHrisAccess"></mat-slide-toggle>
                    </mat-grid-tile>
                    }
                </mat-grid-list>
            </form>
        </mat-card-content>
        @if(canEdit){
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="save()"
                [disabled]="!(editCompanyForm.valid && editCompanyForm.dirty)">
                Save
            </button>
            <button mat-raised-button (click)="reset()" [disabled]="!(editCompanyForm.valid && editCompanyForm.dirty)">
                Reset
            </button>
        </mat-card-actions>
        }
    </mat-card>

    <div style="margin-top: 5px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    [Read-Only]: Software Module Access Configuration
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider style="position: relative; margin: 0 0 25px 0"></mat-divider>

            @if(company.softwareModuleAccessConfiguration){
            <div>
                <mat-grid-list cols="3" rowHeight="75px" style="max-width: 1100px">
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is Deleted</mat-label>
                            <mat-slide-toggle [checked]="
                                    company.softwareModuleAccessConfiguration
                                        .isDeleted
                                " [disabled]="true"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is Anonymized</mat-label>
                            <mat-slide-toggle [checked]="
                                    company.softwareModuleAccessConfiguration
                                        .isAnonymized
                                " [disabled]="true"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Account Classification: </mat-label>
                            {{
                            company.softwareModuleAccessConfiguration
                            .classificationType
                            }}
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>

                @if(company.softwareModuleAccessConfiguration.softwareModuleAccessConfig)
                {
                <table mat-table [dataSource]="smac" class="mat-elevation-z8" style="width: 100%">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Module Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accessLevel">
                        <th mat-header-cell *matHeaderCellDef>Access Level</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.accessLevel }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="experienceType">
                        <th mat-header-cell *matHeaderCellDef>
                            Experience Type
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.experienceType }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accountType">
                        <th mat-header-cell *matHeaderCellDef>Account Type</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.accountType }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                }
                @if(!company.softwareModuleAccessConfiguration.softwareModuleAccessConfig)
                {
                <ng-container>
                    Module Access Configuration not found.
                </ng-container>
                }
            </div>
            }

            @if(!company.softwareModuleAccessConfiguration){
            <div>
                Not found.
            </div>
            }
        </mat-expansion-panel>
    </div>
    <div style="margin-top: 5px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> Company Time Trial </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider style="position: relative; margin: 0 0 25px 0"></mat-divider>
            <p style="position: relative; margin: 0 0 15px 15px">
                Note: All times are UTC format
            </p>

            @if (timeTrials){
            <div>
                <table mat-table [dataSource]="timeTrialsDataSource" class="mat-elevation-z8" style="width: 100%">
                    <ng-container matColumnDef="timeTrialId">
                        <th mat-header-cell *matHeaderCellDef>Time Trial ID</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timeTrialId }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>Product</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.product }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startDate }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.endDate }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="durationDays">
                        <th mat-header-cell *matHeaderCellDef>Duration Days</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.durationDays }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="timeTrialDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: timeTrialDisplayedColumns"></tr>
                </table>
            </div>
            }
        </mat-expansion-panel>
    </div>
    <div style="margin-top: 5px;">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Legacy -> PI2 Upgrade & PI2 Data Correction
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider style="position: relative; margin: 0 0 25px 0"></mat-divider>
            @if(canTriggerCompanyUpgrade){
            <div style="margin-bottom: 15px">
                <button mat-raised-button color="primary" (click)="executeDataCorrection()">
                    Execute PI2 Data Correction
                </button>
            </div>
            }

            <p style="position: relative; margin: 0 0 15px 15px">
                Note: All times are UTC format
            </p>

            <div>
                <table mat-table [dataSource]="upgradeDetailsDataSource" class="mat-elevation-z8" style="width: 100%">
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startTime }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="upgradeDetailsDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="
                            let row;
                            columns: upgradeDetailsDisplayedColumns
                        "></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </div>
    <div style="margin-top: 5px; margin-bottom: 25px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Users
                </mat-panel-title>
            </mat-expansion-panel-header>

            @if(usersResultLoading){
            <mat-spinner></mat-spinner>
            }

            @if(!usersResultLoading){
            <div>
                <table mat-table [dataSource]="usersDataSource" class="mat-elevation-z8" style="width: 100%">
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>FirstName</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user.firstName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef>Last Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user.lastName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user.email }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Legacy Role</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.userCompanies[0].role }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2OrgAdmin">
                        <th mat-header-cell *matHeaderCellDef>Org Admin</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="true"
                                [checked]="element.userCompanies[0].userPermissions.isCompanyAdmin">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2Hire">
                        <th mat-header-cell *matHeaderCellDef>Hire Access</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                            element.userCompanies[0].userPermissions.accessLevel['Hire'] | abacaccesslevelrename
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2Inspire">
                        <th mat-header-cell *matHeaderCellDef>Inspire Access</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                            element.userCompanies[0].userPermissions.accessLevel['Inspire'] | abacaccesslevelrename
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2Design">
                        <th mat-header-cell *matHeaderCellDef>Design Access</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                            element.userCompanies[0].userPermissions.accessLevel['Design'] | abacaccesslevelrename
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2Diagnose">
                        <th mat-header-cell *matHeaderCellDef>Diagnose Access</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                            element.userCompanies[0].userPermissions.accessLevel['Diagnose'] | abacaccesslevelrename
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pi2Cog">
                        <th mat-header-cell *matHeaderCellDef>Cog Level</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.userCompanies[0].userPermissions.cognitiveAccessLevel | abacaccesslevelrename }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="
                            let row;
                            columns: usersDisplayedColumns
                        "></tr>
                </table>
                <mat-paginator #userPaginator [pageSizeOptions]="[5, 10, 20]" [length]="usersCount" showFirstLastButtons
                    aria-label="Select page"></mat-paginator>
            </div>
            }
        </mat-expansion-panel>
    </div>

</div>
}
import { LayoutModule } from '@angular/cdk/layout';
import {
    HttpBackend,
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
    NgModule,
    APP_INITIALIZER,
    Provider,
    forwardRef,
    Injector
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AuthClientConfig,
    AuthConfig,
    AuthModule,
    AuthService
} from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MaterialModule } from '../material.module';
import { ApiModule } from './api/api.module';
import { IpitFrontEndSettings } from './api/models';
import { ApiInterceptor } from './api-intercepter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CodeInfoListComponent } from './codeinfo/code-info-list/code-info-list.component';
import { CodeInfoCardComponent } from './codeinfo/codeinfo-card/codeinfo-card.component';
import { CompanyExtendTimeTrialComponent } from './common/company-extend-time-trial/company-extend-time-trial.component';
import { CompanySearchComponent } from './common/company-search/company-search.component';
import { CompanySearchInputComponent } from './common/company-search-input/company-search-input.component';
import { CompanySearchInputDialogComponent } from './common/company-search-input-dialog/company-search-input-dialog.component';
import { EditCompanyComponent } from './common/edit-company/edit-company.component';
import { EditUserComponent } from './common/edit-user/edit-user.component';
import { EditUserPermissionsDialogComponent } from './common/edit-user-permissions-dialog/edit-user-permissions-dialog.component';
import { UserMoveToCompanyDialogComponent } from './common/user-move-to-company-dialog/user-move-to-company-dialog.component';
import { UserSearchComponent } from './common/user-search/user-search.component';
import { UserSearchDialogComponent } from './common/user-search-dialog/user-search-dialog.component';
import { UserSearchInputComponent } from './common/user-search-input/user-search-input.component';
import { Constants } from './constants/constants';
import { DeleteTeamSessionComponent } from './design/delete-team-session/delete-team-session.component';
import { MigrateTeamComponent } from './design/migrate-teams/migrate-team.component';
import { CognitiveAssessmentResetComponent } from './hire/cognitive-assessment-reset/cognitive-assessment-reset.component';
import { CognitiveAssessmentSearchComponent } from './hire/cognitive-assessment-search/cognitive-assessment-search.component';
import { EditCognitiveAssessmentComponent } from './hire/edit-cognitive-assessment/edit-cognitive-assessment.component';
import { BehavioralAssessmentSearchComponent } from './inspire/behavioral-assessment-search/behavioral-assessment-search.component';
import { EditBehavioralAssessmentComponent } from './inspire/edit-behavioral-assessment/edit-behavioral-assessment.component';
import { HealthCheckModule } from './plg/health-check/health-check.module';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { CopyToClipboardComponent } from './shared/copy-to-clipboard/copy-to-clipboard.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { AuthButtonComponent } from './shared/login/auth-button/auth-button.component';
import { Auth0DialogComponent } from './shared/login/user-profile/auth0-dialog.component';
import { UserProfileComponent } from './shared/login/user-profile/user-profile.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { IncludesPipe } from './shared/pipes/includes.pipe';
import { ABACAccessLevelRenamePipe } from './shared/pipes/abacaccesslevelrename.pipe';
import { ConfigurationService } from './shared/services/configuration.service';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { CircleResponseDialogComponent } from './ui-automation-self-serve-card/circle-response-dialog.component';
import { UiAutomationSelfServeCardComponent } from './ui-automation-self-serve-card/ui-automation-self-serve-card.component';
import { UseropsMovePersonComponent } from './userops/move-person/move-person.component';
import { RestoreArchivedAssessmentsComponent } from './userops/restore-archived-assessments/restore-archived-assessments.component';
import { CopyJobComponent } from './hire/copy-job/copy-job.component';
import { FolderSearchComponent } from './common/folder-search/folder-search/folder-search.component';
import { FolderSearchInputComponent } from './common/folder-search/folder-search-input/folder-search-input.component';
import { FolderSearchInputDialogComponent } from './common/folder-search/folder-search-input-dialog/folder-search-input-dialog.component';
import { UpgradeCompanyDialogComponent } from './common/upgrade-company-dialog/upgrade-company-dialog.component';
import { CopySurveyuserComponent } from './userops/copy-surveyuser/copy-surveyuser.component';

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => ApiInterceptor),
    multi: true
};

@NgModule({
    declarations: [
        AppComponent,
        Auth0DialogComponent,
        AuthButtonComponent,
        CircleResponseDialogComponent,
        CodeInfoCardComponent,
        CodeInfoListComponent,
        DashboardComponent,
        NavbarComponent,
        UiAutomationSelfServeCardComponent,
        UserProfileComponent,
        SidebarComponent,
        UserSearchComponent,
        CompanySearchComponent,
        BehavioralAssessmentSearchComponent,
        CognitiveAssessmentSearchComponent,
        DeleteTeamSessionComponent,
        MigrateTeamComponent,
        EditUserComponent,
        EditCompanyComponent,
        EditBehavioralAssessmentComponent,
        EditCognitiveAssessmentComponent,
        UserSearchInputComponent,
        UserSearchDialogComponent,
        UserMoveToCompanyDialogComponent,
        CompanySearchInputDialogComponent,
        CompanySearchInputComponent,
        ConfirmDialogComponent,
        CopyToClipboardComponent,
        CompanyExtendTimeTrialComponent,
        EditUserPermissionsDialogComponent,
        IncludesPipe,
        ABACAccessLevelRenamePipe,
        UseropsMovePersonComponent,
        RestoreArchivedAssessmentsComponent,
        CognitiveAssessmentResetComponent,
        CopyJobComponent,
        FolderSearchComponent,
        FolderSearchInputComponent,
        FolderSearchInputDialogComponent,
        UpgradeCompanyDialogComponent,
        CopySurveyuserComponent
    ],
    imports: [
        HttpClientModule,
        AuthModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MaterialModule,
        LayoutModule,
        HealthCheckModule,
        ReactiveFormsModule,
        FormsModule,
        ApiModule.forRoot({ rootUrl: environment.apiUrl })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [HttpBackend, AuthClientConfig, Injector],
            multi: true
        },
        ApiInterceptor,
        API_INTERCEPTOR_PROVIDER
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    public static Auth0Settings: AuthConfig;
}

const siteKeysRegistered = new Set<string>();
function initializeApp(
    httpBackend: HttpBackend,
    config: AuthClientConfig,
    injector: Injector
) {
    return async (): Promise<void> => {
        const response = await firstValueFrom(
            new HttpClient(httpBackend).get(
                `${environment.apiUrl}/FrontEndSettings`
            )
        );
        const settings = response as IpitFrontEndSettings;
        config.set({
            domain: settings.auth0Domain ?? '',
            clientId: settings.auth0ClientId ?? '',
            useRefreshTokens: true,
            useRefreshTokensFallback: true,
            authorizationParams: {
                connection: 'google-oauth2', // forces SSO only
                audience: settings.auth0Audience ?? '',
                scope: settings.auth0Scope ?? '',
                redirect_uri: window.location.origin
            }
        });

        const auth = injector.get(AuthService);
        const isAuthenticated: boolean = await firstValueFrom(
            auth.isAuthenticated$
        );

        if (isAuthenticated) {
            const configService = injector.get(ConfigurationService);
            await configService.load();
            if (environment.overrideEnvironmentName !== null) {
                configService.configuration.environmentName =
                    environment.overrideEnvironmentName;
            }
            return registerRecaptcha();
        }

        return firstValueFrom(auth.loginWithRedirect());
    };
}

/**
 * There is a limitation in the ng-recaptcha and recaptcha-v3 npm libraries, only one instance of the
 * recaptcha script with a given site key can be loaded or registered at a time
 * in the health check app we have 4 different environments with 2 different site keys.
 * A custom minimal "hack" was added below to load both google recaptcha scripts on angular init.
 *
 * In the health check card the global 'grecaptcha' instance will correctly use the site key for the relevant script.
 * (Internally implemented by google)
 */
function registerRecaptcha(): void {
    Constants.recaptchaSiteKeys.forEach((siteKey, _) => {
        if (!siteKeysRegistered.has(siteKey)) {
            const script = document.createElement('script');
            script.setAttribute(
                'src',
                `https://www.google.com/recaptcha/api.js?render=${siteKey}`
            );

            document.head.appendChild(script);
            siteKeysRegistered.add(siteKey);
        }
    });
}

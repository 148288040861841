import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { UserCto } from 'src/app/api/models';
import { UserService } from 'src/app/api/services';

export const userSearchValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const firstName = control.get('firstName');
    const lastName = control.get('lastName');
    const email = control.get('email');
    const userGuid = control.get('userGuid');
    const userId = control.get('userId');
    const apiUserId = control.get('apiUserId');
    const apiUserCompanyId = control.get('apiUserCompanyId');
    const companyId = control.get('companyId');

    if (
        email?.value ||
        userGuid?.value ||
        userId?.value ||
        apiUserId?.value ||
        apiUserCompanyId?.value ||
        (firstName?.value && lastName?.value) ||
        companyId?.value
    ) {
        return null;
    }

    if (firstName?.value && !lastName?.value) {
        return { lastNameRequired: true };
    }

    if (!firstName?.value && lastName?.value) {
        return { firstNameRequired: true };
    }

    return { oneOfRequired: true };
};

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent {
    @Output() userSelected = new EventEmitter<string>();

    @Input() selectionType: 'guid' | 'long' = 'guid';

    @Input() userSearchTitle: string = 'User Search';

    public displayedColumns: string[] = [
        'select',
        'firstName',
        'lastName',
        'email',
        'company',
        'userGuid',
        'userId'
    ];

    public searchResults: UserCto[] = [];

    public userSearchForm = this.fb.group(
        {
            firstName: [null],
            lastName: [null],
            userGuid: [null],
            userId: [null],
            apiUserId: [null],
            apiUserCompanyId: [null],
            email: [null],
            companyId: [null]
        },
        { validators: userSearchValidator }
    );

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private readonly userService: UserService
    ) {}

    get email(): string | undefined {
        return this.userSearchForm.controls.email.value ?? undefined;
    }

    get firstName(): string | undefined {
        return this.userSearchForm.controls.firstName.value ?? undefined;
    }

    get lastName(): string | undefined {
        return this.userSearchForm.controls.lastName.value ?? undefined;
    }

    get userGuid(): string | undefined {
        return this.userSearchForm.controls.userGuid.value ?? undefined;
    }

    get userId(): number | undefined {
        return this.userSearchForm.controls.userId.value ?? undefined;
    }

    get apiUserId(): string | undefined {
        return this.userSearchForm.controls.apiUserId.value ?? undefined;
    }

    get apiUserCompanyId(): string | undefined {
        return this.userSearchForm.controls.apiUserCompanyId.value ?? undefined;
    }

    get companyId(): number | undefined {
        return this.userSearchForm.controls.companyId.value ?? undefined;
    }

    public search(): void {
        if (this.userSearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.userService
            .getUsers$Json({
                Email: this.email,
                ApiUserId: this.apiUserId,
                FirstName: this.firstName,
                LastName: this.lastName,
                UserGuid: this.userGuid,
                UserId: this.userId,
                ApiUserCompanyId: this.apiUserCompanyId,
                CompanyId : this.companyId
            })
            .subscribe((users) => {
                this.loading = false;
                this.searchResults = users;
            });
    }

    public reset(): void {
        this.userSearchForm.reset();
        this.searchResults = new Array<UserCto>();
    }

    public selectUser(user: UserCto): void {
        this.userSelected.emit(
            this.selectionType == 'guid'
                ? user.userGuid
                : user.userId?.toString()
        );
    }
}
